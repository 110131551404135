var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Contextual Badges" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeContextual) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("Add any of the following variants via the ")]),
        _c("code", [_vm._v("variant")]),
        _c("span", [_vm._v(" prop to change the appearance of a ")]),
        _c("code", [_vm._v("<b-badge>:")]),
        _c("span", [_vm._v(" default, primary, success, warning, info,")]),
        _c("span", [_vm._v(" and ")]),
        _c("span", [_vm._v("danger.")]),
        _c("span", [_vm._v(" If no variant is specified ")]),
        _c("code", [_vm._v("default")]),
        _c("span", [_vm._v(" will be used.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c("b-badge", { attrs: { variant: "primary" } }, [
            _vm._v(" Primary ")
          ]),
          _c("b-badge", [_vm._v(" Secondary ")]),
          _c("b-badge", { attrs: { variant: "success" } }, [
            _vm._v(" Success ")
          ]),
          _c("b-badge", { attrs: { variant: "danger" } }, [_vm._v(" Danger ")]),
          _c("b-badge", { attrs: { variant: "warning" } }, [
            _vm._v(" Warning ")
          ]),
          _c("b-badge", { attrs: { variant: "info" } }, [_vm._v(" Info ")]),
          _c("b-badge", { attrs: { variant: "dark" } }, [_vm._v(" Dark ")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }