var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Link Badge" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeLink) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Use ")]),
        _c("code", [_vm._v("href")]),
        _c("span", [_vm._v(" prop (links) or ")]),
        _c("code", [_vm._v("to")]),
        _c("span", [_vm._v(" prop (router-links) for link badges.")])
      ]),
      _c(
        "b-badge",
        { attrs: { href: "#", variant: "primary" } },
        [
          _c("feather-icon", {
            staticClass: "mr-25",
            attrs: { icon: "LinkIcon" }
          }),
          _c("span", [_vm._v("Link Badge")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }